<template>
  <template v-if="enrollmentPeriod > 0">
    {{
      i18n(enrollmentTranslation, {
        duration: getFormattedDuration(enrollmentPeriod),
      })
    }}
  </template>

  <template v-else> {{ i18n(translations.noContract) }} </template>
</template>

<script setup lang="ts">
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'

import translations from './EnrollmentPeriod.translations'

defineProps<{
  enrollmentPeriod: number
  enrollmentTranslation: I18nDefinition
}>()

const i18n = useI18n()

const { getFormattedDuration } = useFormatMonthsYearsDuration()
</script>
